<template>
  <div>
     
        <paginate v-if="!dataLoading && items.length !== 0"
                :page-count="pageCount"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :click-handler="handleChange">
        </paginate>

  </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: [
        'items',
        'pageCount',
        'dataLoading', 
    ],
    data(){
        return {
            //
        }
    },
    mounted() {},
    created() {},
    methods: {
        //
        handleChange(event) {
            this.$emit('pageChange', event)
        },
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
